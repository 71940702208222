$perimeter: 255 * 2 * 3.14159; // 2 * pi * r

.wrapper {
  flex: 1;
  position: relative;
  display: grid;
  place-content: center;
  width: 100%;
  min-height: 640px;
  font-family: 'bebasneue-book';
  background: #2b2a30;
  color: #fff;
  text-align: center;
}

.circle {
  --size: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  background: radial-gradient(
    71.4% 71.4% at 51.7% 28.6%,
    #3a393f 0%,
    #17171a 100%
  );
  box-shadow: inset 0px 0px 114px rgba(0, 0, 0, 0.45);
  border: 10px solid #000;
  box-sizing: content-box;
  z-index: 2;
}

.time {
  display: inline-block;
  margin-top: 140px;
  font-size: 196px;
  line-height: 196px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &__minute,
  &__second {
    display: inline-block;
    width: 150px;
  }

  &__minute {
    text-align: right;
  }

  &__second {
    text-align: left;
  }
}

.btn-start {
  $letter-spacing: 0.6em;
  width: 300px;
  height: 28px;
  margin-top: 35px;
  margin-bottom: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: $letter-spacing;
  margin-right: -$letter-spacing; // hack to remove spacing at last letter
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff;
  user-select: none;
}

.btn-setting {
  width: 50px;
  cursor: pointer;
  user-select: none;

  &__icon {
    margin: auto;
    width: 30px;
  }
}

.ring-svg,
.drop-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  pointer-events: none;
}

.ring-svg {
  z-index: 3;
}

.drop-svg {
  z-index: 1;
}

.ring {
  fill: transparent;
  stroke: var(--ring-color);
  stroke-width: 10;
  stroke-dasharray: $perimeter;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset var(--duration) linear;

  &.animate {
    stroke-dashoffset: -$perimeter;
  }
}
