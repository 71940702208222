*, *::before, *::after {
  box-sizing: border-box;
}

body {
  font-family: Nunito, sans-serif;
  font-size: var(--font-base);
  color: var(--c-text);
  overflow-y: overlay;
}

a.link {
  color: var(--c-theme);
  text-decoration: none;
}

svg {
  display: inline-block;
}