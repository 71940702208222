.links {
  list-style: none;
  margin: 0;
  padding: 20px 0;
  text-align: center;

  li {
    display: inline-block;
    padding: 0 10px;

    &:not(:last-child) {
      border-right: 1px solid var(--c-border);
    }

    a {
      color: var(--c-text);

      &.active {
        color: var(--c-theme);
      }
    }
  }
}

.btnInformation {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 20px;
  z-index: 999;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 20%);
}

.github {
  position: fixed;
  top: 23px;
  right: 70px;
  z-index: 999;
}

.infoList {
  list-style-type: disc;
  padding-left: 15px;

  strong {
    color: var(--c-theme);
  }
}